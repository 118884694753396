import React from "react"

import Layout from "../components/layout"
import Main from '../components/main';

const NotFoundPage = () => (
  <Layout>
    <Main />
  </Layout>
)

export default NotFoundPage
